import Vue from "vue"
import DatePicker from 'vue2-datepicker'
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    DatePicker,
    Paginate
  },
  data: function() {
    return {
      busy: false,
      date_start: null,
      date_end: null,
      sale_date_start: null,
      sale_date_end: null,
      perPage: 6,
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      totalItem: 0,
      apiStatus: 'ไม่พบข้อมูล',
      status: null,
      fields: [
        { 
          key: 'index', 
          label: '#', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'branch_code', 
          label: 'รหัสสินค้า', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'description', 
          label: 'รายละเอียดสินค้า', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2 text',
          sortable: false 
        },
        { 
          key: 'cost', 
          label: 'มูลค่า', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2 text',
          sortable: false 
        },
        { 
          key: 'size', 
          label: 'ความหนา', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2 text',
          sortable: false 
        },
        { 
          key: 'color', 
          label: 'สี', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2 text',
          sortable: false 
        },
        { 
          key: 'length', 
          label: 'ยาว', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2 text',
          sortable: false 
        },
        { 
          key: 'net_weight', 
          label: 'น้ำหนัก', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2 text',
          sortable: false 
        }
      ],
      items: [
        // {
        //   _id: '123456',
        //   doc_no: 'WD-201665156',
        //   doc_date: new Date(),
        //   doc_so: 'SO-201665156',
        //   date_so: new Date(),
        //   emp: 'ไพศาล',
        //   approver: 'สมชาย',
        //   qty: 2,
        //   status: '<label class="badge badge-info">On hold</label>'
        // }
      ]
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: {
    getContent(item) {
      if (item.brand === "BHP") {
        return item.size + " " + item.color + " " + item.brand + "\nB" + item.lot_no + " " + (item.code ? item.code : '') + " " + item.coil

      } else if (item.brand === "JJ") {
        return item.size +
          " " +
          item.color +
          " " +
          item.brand +
          "\nJ" +
          item.lot_no +
          " " +
          (item.code ? item.code : '') +
          " " +
          item.coil
      } else if (item.brand === "EM-STAR") {
        return  item.size +
          " " +
          item.color +
          " " +
          item.brand +
          "\nE" +
          item.lot_no +
          " " +
          (item.code ? item.code : '') +
          " " +
          item.coil
      } else {
        return item.size +
          " " +
          item.color +
          " " +
          item.brand +
          "\nL" +
          item.lot_no +
          " " +
          (item.code ? item.code : '') +
          " " +
          item.coil
      }
    },
    async onInitData() {
      try {
        const item = this.items
        this.items = []
        this.busy = true
        this.apiStatus = 'กำลังค้นหาข้อมูล..'
        
        const res = await this.$axios.get(
          this.$host + "/api/v1/report/stock",
                )

        const data = res.data

        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'
        this.items = data.data
        
      } catch (error) {
        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'
        this.onExceptionHandler(error.response.data.message);
      }
    },
    exportPDFReport() {
      this.loading = true;
      setTimeout(async () => {
        this.loading = true

        const result = await this.$axios.get(
          this.$host + "/api/v1/report/pdf/stock",

          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            responseType: "blob",
          }
        );
        const blob = new Blob([result.data], { type: "application/pdf" });
        const objectUrl = URL.createObjectURL(blob);
        var isSafari = /^((?!chrome|android).)*safari/i.test(
          navigator.userAgent
        );
        if (isSafari) {
          window.open(objectUrl)
          // const link = document.createElement('a')
          // link.href = objectUrl2
          // link.setAttribute('download', 'ใบเสนอราคา ' +  + '.pdf')
          // document.body.appendChild(link)
          // link.click()

          this.loading = false;
        } else {
          window.open(
            objectUrl,
            "targetWindow",
            `toolbar=no,
           location=no,
           status=no,
           menubar=no,
           scrollbars=yes,
           resizable=yes,
           width=SomeSize,
           height=SomeSize`
          );
          this.loading = false;
        }
        // URL.revokeObjectURL(objectUrl);
      }, 1000);
    },
    async exportExcelReport() {
      try {
        this.loading = true;
        
        const response = await this.$axios.get(
          this.$host + "/api/v1/report/excel/stock",
          { responseType: 'blob' }
        );
    
        const url = URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Full rolls report.xls`);
        
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Cleanup after click
    
      } catch (error) {
        this.onExceptionHandler(error?.response?.data?.message || "An error occurred while downloading the file.");
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted(){
    await this.onInitData()
  }
}